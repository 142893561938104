@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Shrikhand&display=swap");

body {
  font-family: "Shrikhand", serif;

  color: white;
  font-weight: 200;
  font-style: normal;
  background-color: rgb(76, 149, 64);
}

@layer components {
  .nav_components {
    @apply text-xl text-white ml-5 my-auto hidden md:inline;
  }
}
